<template>
  <div class="app-questions list">
    <PageHeader
      :title="`Sondages ${domain.label}`"
      description="Choix des sondages issues de la base de médias Maviepro"
    />
    <SurveysSubmenu />
    <PageContent>
      <MVPTable :gridData="gridData" :options="gridOptions" />
    </PageContent>
  </div>
</template>

<script>
import { http } from '@/services/api.service'
import { mapGetters } from 'vuex'
import MVPTable from '@/components/agGrid/MVPTable.vue'
import AG_GRID_LOCALE_FR from '@/assets/ag-grid.locale.fr.json'
import PageHeader from '@/components/PageHeader.vue'
import PageContent from '@/components/PageContent.vue'
import ButtonCellRenderer from '@/components/agGrid/ButtonCellRenderer.vue'
import SurveysSubmenu from './SurveysSubmenu.vue'
import ListRowDetail from './ListRowDetail.vue'

export default {
  name: 'app-surveys-media-mvp',
  components: {
    PageHeader,
    SurveysSubmenu,
    PageContent,
    ButtonCellRenderer, //eslint-disable-line
    ListRowDetail, //eslint-disable-line
    MVPTable
  },
  computed: {
    ...mapGetters(['roles', 'user', 'domain']),
    hasEditionRights () {
      return this.roles.some(role => ['admin', 'director', 'community-manager'].includes(role))
    },
    columnDefs () {
      return [
        {
          headerName: 'Question',
          field: 'question'
        },
        {
          headerName: 'Date de publication par Maviepro',
          field: 'created_at',
          cellRenderer: 'DateCellRenderer'
        },
        {
          headerName: 'Action',
          field: 'button',
          cellRenderer: 'ButtonCellRenderer',
          cellRendererParams: {
            onClick: this.duplicate
          },
          width: 200,
          hide: !this.hasEditionRights
        },
        {
          headerName: 'Dupliqué ?',
          field: 'duplicated_by',
          valueGetter: params => {
            const duplicatedBy = params.data.duplicated_by
            const domainUuid = this.domain.uuid
            return duplicatedBy && duplicatedBy.includes(domainUuid) ? 'Oui' : 'Non'
          },
          sortable: true,
          filter: true
        }
      ]
    }
  },
  data () {
    return {
      surveys: [],
      gridData: null,
      gridOptions: {
        rowModelType: 'clientSide',
        localeText: AG_GRID_LOCALE_FR,
        masterDetail: true,
        detailCellRenderer: 'ListRowDetail',
        detailRowAutoHeight: true,
        columnDefs: [],
        defaultColDef: {
          resizable: true,
          floatingFilter: true
        }
      }
    }
  },
  watch: {
    roles () {
      this.updateColumnDefs()
    }
  },
  mounted () {
    this.getSurveys()
    this.updateColumnDefs()
  },
  methods: {
    getSurveys () {
      this.loading = true
      http
        .get('/surveys/surveys-mvp')
        .then(res => {
          this.loading = false
          this.surveys = res.data
          this.gridData = res.data
        })
        .catch(err => {
          console.log(err)
          this.$buefy.notification.open({
            duration: 3000,
            message:
              'Nos serveurs rencontrent des difficultés à récuperer la liste des sondages',
            position: 'is-bottom-left',
            type: 'is-warning',
            hasIcon: true,
            icon: 'server'
          })
        })
    },
    duplicate (survey) {
      this.$buefy.dialog.confirm({
        title: 'Duplication',
        message:
          "Voulez-vous vraiment dupliquer ce sondage ?<br><br>En confirmant, le sondage sera automatiquement dupliqué sur votre domaine et vous serez redirigé vers l'édition de ce dernier.",
        confirmText: 'Dupliquer',
        cancelText: 'Annuler',
        type: 'is-danger',
        onConfirm: () => {
          http.post(`/surveys/${survey.uuid}/duplicate`).then(
            res => {
              this.$router.push({ path: `/app/surveys/${res.data.uuid}` })
            },
            err => {
              console.log(err)
            }
          )
        }
      })
    },
    updateColumnDefs () {
      this.gridOptions.columnDefs = this.columnDefs
      if (this.gridOptions.api) {
        this.gridOptions.api.setColumnDefs(this.columnDefs)
      }
    }
  }
}
</script>
